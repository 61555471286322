<template>
  <div class="about" id="about">
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <div class="display">
            <img src="/profile.jpg" alt="" />
            <div class="title">
              <h1>Rhyz Kotov</h1>
              <h2>Graphic Designer</h2>
            </div>
          </div>
        </div>
        <div class="flip-card-back">
          <div class="display justify-start">
            <div class="head">
              <img src="/profile.jpg" class="small" alt="" />
              <h1>Rhyz Kotov</h1>
            </div>
            <div class="listings">
              <div class="listing">
                <ul>
                  <li class="title">Skills:</li>
                  <li>Microsoft Office</li>
                  <li>Adobe Creative Suite</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                  <li>Unity</li>
                  <li>Time Management</li>
                  <li>Creative Thinking</li>
                  <li>Teamwork</li>
                  <li>Problem Solving</li>
                </ul>
                <ul>
                  <li class="title">Exprerience:</li>
                  <li>
                    Honex <br />
                    <span class="darken">(2022 August - Present)</span>
                    <ul>
                      <li>Graphic Designer</li>
                      <li>Motion Artist</li>
                    </ul>
                  </li>
                  <li>
                    Soulgate Studio UA <br />
                    <span class="darken">(2021 November - Present)</span>
                    <ul>
                      <li>Voice Actor</li>
                      <li>Graphic Designer</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listing {
  display: flex;
  gap: 20px;
  ul {
    width: 100%;
  }
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  li {
    margin-bottom: 10px;
    font-size: 16px;
    @media only screen and (max-width: 600px) {
      font-size: 10px;
    }
    padding-left: 10px;
  }
  .title {
    font-weight: bold;
  }
}
.darken {
  color: #949494;
}
.justify-start {
  align-items: flex-start !important;
  @media only screen and (max-width: 600px) {
    align-items: center !important;
  }
}
.small {
  width: 100px !important;
  height: 100px !important;
}
.about {
  display: flex;
  padding: 15px;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.flip-card {
  background-color: transparent;
  width: 850px;
  height: 500px;
  border-radius: 25px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  //make vertical on mobile
  @media only screen and (max-width: 940px) {
    border-radius: 0px;
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 2s;
  transform-style: preserve-3d;
}

img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  //white and black text shadow
  text-shadow: 0px 2px 8px rgb(0, 0, 0);
  box-shadow: 0px 2px 8px rgb(0, 0, 0);
}

.display {
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  gap: 20px;
  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    //white and black text shadow
    text-shadow: 0px 2px 8px rgb(0, 0, 0);
    box-shadow: 0px 2px 8px rgb(0, 0, 0);
    animation: title 1.5s ease-in-out infinite;
  }
  .title {
    h1 {
      font-family: Oxygen;
      font-size: 40px;
      font-weight: normal;
      color: whitesmoke;
    }
    h2 {
      font-family: Reenie Beanie;
      font-size: 24px;
      font-weight: normal;
      color: whitesmoke;
    }
  }
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #252a3d;
  border-radius: 25px;
  border: solid 1px rgb(255, 255, 255);
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

  width: 100%;
  border-radius: 25px;
  background-color: #252a3d;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //set flex to column on mobile
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  perspective: 1000px;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: black;
  // don't set angle on mobile
  @media only screen and (max-width: 940px) {
    transform: rotateY(0deg) !important;
  }
  transform: rotateY(5deg);
}

/* Style the back side */
.flip-card-back {
  color: white;
  // don't set angle on mobile
  @media only screen and (max-width: 940px) {
    transform: rotateY(180deg) !important;
  }
  transform: rotateY(175deg);
}
</style>
