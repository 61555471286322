<template>
  <div class="posters">
    <div class="card">
      <div class="card-body">
        <div class="poster" onclick="">
          <img src="\posters\poster-0.jpg" alt="" />
          <div class="overlay">
            <p>
              I was insipered by transformers cover of comics IDW Publishing and
              desided to make of this concept poster for event Kino na Granici
            </p>
          </div>
        </div>
        <div class="poster" onclick="">
          <img src="\posters\poster-1.jpg" alt="" />
          <div class="overlay">
            <p>
              I decided to create a poster of my favorite k-pop band that is
              disbanded and each of members of the band went their own way(it's
              sad for me and for fans VIP). So I decided to imagen about their
              last "goodbye" concert for their fans that were waiting for their
              comeback since 2016
            </p>
          </div>
        </div>
        <div class="poster" onclick="">
          <img src="\posters\poster-2.jpg" alt="" />
          <div class="overlay">
            <p>
              I was inspired by Hanz Zimmer's music, l decided to dreaming about
              live concert of his music so I created poster for concert where
              everyone could listen his music alive
            </p>
          </div>
        </div>
        <div class="poster" onclick="">
          <img src="\posters\poster-3.jpg" alt="" />
          <div class="overlay">
            <p>
              We have a task to create poster for fake event of modern artist.
              SO we can learn the basics of creating, position, fonts and others
              elements on the poster. I combined 2 Adobe programs Photoshop (for
              working with background and light) and Illustrator (for text
              elements)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioPosters",
};
</script>

<style lang="scss" scoped>
.posters {
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
  }
  .card {
    border-radius: 25px;
    background-color: #252a3d;
    display: flex;
    perspective: 1000px;
    // set size to 100% on small screens
    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
    position: relative;
    .card-body {
      margin: 40px;

      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 950px) {
        flex-direction: column;
      }
      .poster {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        &:hover {
          .overlay {
            opacity: 1;
          }
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 25px;
        }
        .overlay {
          position: absolute;
          transition: all 250ms;
          cursor: default;

          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 25px;
          background-color: rgba(0, 0, 0, 0.738);

          p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            width: 95%;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
</style>
