<template>
  <div class="portfolio" id="portfolio">
    <PortfolioAnimals class="portfolio-section" />
    <PortfolioPosters class="portfolio-section" />
    <PortfolioKino class="portfolio-section" />
  </div>
</template>

<script>
import PortfolioAnimals from "@/components/PortfolioAnimals.vue";
import PortfolioPosters from "@/components/PortfolioPosters.vue";
import PortfolioKino from "@/components/PortfolioKino.vue";
export default {
  components: {
    PortfolioAnimals,
    PortfolioPosters,
    PortfolioKino,
  },
  name: "PortfolioView",
};
</script>

<style lang="scss" scoped>
.portfolio {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
