<template>
  <div class="kino">
    <div class="card">
      <div class="card-icon">
        <img src="\kino\kino-0.jpg" alt="" />
      </div>
      <div class="card-text">
        <div class="card-title">Kino na Hranici/ Kino na Granicy</div>
        <div class="card-body">
          <div class="section">
            <!-- <video autoplay loop>
              <source src="\kino\kino-vid.mp4" type="video/mp4" />
            </video> -->
          </div>
          <div class="section small">
            <img src="\kino\kino-1.jpg" alt="" />
            <p>
              A project depicting a poster and a brochure for the Kino na
              Hranici event
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioKino",
};
</script>

<style lang="scss" scoped>
.kino {
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    &:before {
      background-image: url("@/assets/kino.jpg");

      content: "";
      position: fixed;
      left: 0;
      right: 0;
      z-index: -1;

      display: block;
      background-size: cover;
      border-radius: 25px;
      width: 100%;
      height: 100%;

      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
    }
    position: relative;
    border-radius: 25px;
    background-color: #252a3d;
    margin-top: 100px;
    display: flex;
    perspective: 1000px;
    position: relative;

    // set size to 100% on small screens
    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
    .card-icon {
      height: 250px;
      position: absolute;
      top: -20px;
      left: -20px;
      transform: rotateY(15deg);
      // hide on small screens
      @media only screen and (max-width: 600px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 0px !important;
      }
    }
    .card-text {
      margin: 40px;
      margin-left: 200px;
      // remove margin on small screens
      @media only screen and (max-width: 600px) {
        margin-left: 40px;
      }
      .card-title {
        font-size: 30px;
        font-family: Oxygen;
        padding: 10px;
        text-shadow: 0px 2px 8px rgb(0, 0, 0);
      }
      .card-body {
        display: flex;
        gap: 20px;
        text-shadow: 0px 2px 8px rgb(0, 0, 0);

        @media only screen and (max-width: 600px) {
          flex-direction: column;
        }
      }
      .section {
        width: 100%;
        p {
          font-size: 24px;
        }
      }
      .small {
        width: 60%;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
}
img,
video {
  width: 100%;
  border-radius: 25px;
}
</style>
