<template>
  <div class="home" id="home">
    <div class="hedder">
      <blobsBackgruond class="blobs" />
      <div class="hedder-content">
        <div class="hedder-title">Rhyz Kotov</div>
        <transition name="fade" mode="out-in">
          <div class="hedder-subtitle" v-if="shownHedder == 0">
            Graphic Designer
          </div>
          <div class="hedder-subtitle" v-else-if="shownHedder == 1">Artist</div>
          <div class="hedder-subtitle" v-else>Voice Actor</div>
        </transition>
        <div class="icon">
          <i
            :class="`fa-solid fa-arrow-down ${hovered ? '' : 'fa-bounce'} `"
            ref="icon"
            @mouseenter="hovered = true"
            @mouseleave="hovered = false"
            @click="scrollToElement()"
            style="
              --fa-bounce-start-scale-x: 1;
              --fa-bounce-start-scale-y: 1;
              --fa-bounce-jump-scale-x: 1;
              --fa-bounce-jump-scale-y: 1;
              --fa-bounce-land-scale-x: 1;
              --fa-bounce-land-scale-y: 1;
            "
          ></i>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="footer-section">
          <div class="section-links">
            <a
              href="https://www.linkedin.com/in/daria-anisimova-5626a5219/"
              target="_blank"
              ><i class="fa-brands fa-linkedin"></i
            ></a>
            <a href="https://twitter.com/SwettyLord_SQQ" target="_blank"
              ><i class="fa-brands fa-twitter"></i
            ></a>
            <a href="https://t.me/LMsmokingWinston" target="_blank"
              ><i class="fa-brands fa-telegram"></i
            ></a>
            <a
              href="https://www.instagram.com/rk900_313_248_317_87/"
              target="_blank"
              ><i class="fa-brands fa-instagram"></i
            ></a>
          </div>
        </div>
        <div class="footer-section"></div>
        <div class="footer-section">
          <transition name="fade" mode="out-in">
            <div class="footer-text" v-if="!stopSubtitle">
              My work reflects a fusion of past influences and contemporary
              elements, resulting in visually striking designs that evoke
              nostalgia and curiosity
            </div>
            <div class="footer-text" v-else>Let me show you my journey</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import blobsBackgruond from "@/components/BlobsBackgruond.vue";

export default {
  name: "HomeView",
  components: {
    blobsBackgruond,
  },
  data() {
    return {
      shownHedder: 0,
      stopSubtitle: true,
      hovered: false,
    };
  },
  mounted() {
    this.animateHedder();
  },
  methods: {
    scrollToElement() {
      document.getElementById("about").scrollIntoView();
    },
    animateHedder() {
      setInterval(() => {
        this.shownHedder++;
        if (this.shownHedder > 2) {
          this.shownHedder = 0;
          this.stopSubtitle = true;
        }
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .hedder {
    position: relative;
    width: 100%;
    z-index: 1;
    height: calc(100vh - 280px);

    .blobs {
      //no interaction
      pointer-events: none;
      position: absolute;
      background-color: rgb(8, 8, 8);
      min-width: 100vw;
      height: 100%;
      z-index: -1;
    }
    .hedder-content {
      position: absolute;
      top: 60px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      z-index: 2;
      .hedder-title {
        font-size: 2rem;
        text-shadow: 0px 2px 8px rgb(0, 0, 0);
        color: #d4d4d4;
        font-family: "Oxanium", sans-serif;
        animation: title 1.5s ease-in-out infinite;
      }
      .hedder-subtitle {
        text-shadow: 0px 2px 8px rgb(0, 0, 0);
        color: #d4d4d4;
        mix-blend-mode: difference;
        font-family: Oxygen, sans-serif;
        font-size: 3rem;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: #262424;
    padding-bottom: 40px;
    padding-top: auto;
    .footer-content {
      width: 90%;
      display: flex;
      align-content: space-between;
      justify-content: center;
      gap: 50px;
      :nth-child(2) {
        width: 100%;
      }
      .footer-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        .section-title {
          font-family: Oxygen;
          font-size: 40px;
          //hide if screen is small
          @media only screen and (max-width: 450px) {
            display: none;
          }
        }
        .section-links {
          display: flex;
          padding-top: 2rem;
          gap: 20px;
          a {
            background-color: #262424;
            height: 80px;
            width: 80px;
            font-size: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: whitesmoke;
            &:hover {
              background-color: whitesmoke;
              color: #262424;
              cursor: pointer;
              transition: 0.5s;
            }
          }
        }
        .footer-text {
          text-align: right;
          font-family: Oxygen;
          width: 100%;
          padding-top: 3rem;
          font-size: 32px;
          line-height: 115%; /* 36.8px */
          letter-spacing: 4.8px;
          //hide if screen is small
          @media only screen and (max-width: 1000px) {
            display: none;
          }
        }
      }
    }
  }
}

.icon {
  font-size: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  &:hover {
    cursor: pointer;
    transition: 150ms;
    color: whitesmoke;
    //shadow
    text-shadow: 1px 0 10px rgb(255, 255, 255);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
