<template id="app">
  <navItems />
  <div class="page">
    <HomeView />
    <AboutView class="page-view" />
    <PortfolioView class="page-view" />
    <!-- <FooterComponent /> -->
  </div>
</template>

<script>
import navItems from "./components/navItems.vue";
import HomeView from "./views/HomeView.vue";
import AboutView from "./views/AboutView.vue";
import PortfolioView from "./views/PortfolioView.vue";
// import FooterComponent from "./components/FooterComponent.vue";
export default {
  components: {
    navItems,
    HomeView,
    AboutView,
    PortfolioView,
    // FooterComponent,
  },
  name: "App",
  data() {
    return {
      active: "Home",
    };
  },
  methods: {
    updateActive(active) {
      this.active = active;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Doppio+One&family=Dosis:wght@400;600&family=Fjord+One&family=Inter&family=Oxanium:wght@200&family=Oxygen:wght@400;700&family=Reenie+Beanie&display=swap");
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body,
#app {
  margin: 0;
  padding: 0;
  font-family: "Oxygen", sans-serif;
  height: 100vh;
  overflow: hidden;
  background: #161515;
  color: whitesmoke;
}
.page {
  height: calc(100vh - 134px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 100px;

  .page-view {
    padding-bottom: 60px;
    margin-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 1000px;
  justify-content: center;
  align-items: center;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #616161 #929292;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #5e4c4c00;
}

*::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;
  border-radius: 10px;
  border: 0px none #ffffff;
}
</style>

<style lang="scss">
.card {
  margin-top: 50px;
}
</style>
