<template>
  <video autoplay muted loop id="myVideo">
    <source src="../assets/bg.mp4" type="video/mp4" />
  </video>
</template>

<script>
export default {};
</script>

<style lang="scss">
video {
  background-color: black;
  // center and scale the video
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}
</style>
