<template>
  <nav>
    <div class="nav-item">
      <router-link class="logo" @click="scrollToElement('home')" to="/"
        ><img src="../assets/logo.png" alt=""
      /></router-link>
      <div class="nav-hedder">
        <div class="hedder">Rhyz Kotov</div>
        <div class="sub-hedder">
          Graphical Designer <span class="and">And</span> Artist
        </div>
      </div>
    </div>
    <div class="nav-item">
      <router-link
        @click="scrollToElement('home')"
        :class="`nav-link ${active === 'Home' ? 'active' : ''}`"
        ><span class="text">Home</span> <i class="fa-solid fa-house"></i
      ></router-link>
      <router-link
        @click="scrollToElement('about')"
        :class="`nav-link ${active === 'About' ? 'active' : ''}`"
        ><span class="text">About</span> <i class="fa-solid fa-user"></i
      ></router-link>
      <router-link
        @click="scrollToElement('portfolio')"
        :class="`nav-link ${active === 'Portfolio' ? 'active' : ''}`"
      >
        <span class="text">Portfolio</span>
        <i class="fa-solid fa-briefcase"></i>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navItems",
  methods: {
    scrollToElement(element) {
      document.getElementById(element).scrollIntoView();
    },
  },
};
</script>

<style lang="scss">
nav {
  height: 120px;
  @media only screen and (max-width: 900px) {
    height: 80px;
  }
  border-bottom: 2px white solid;
  padding: 15px;
  margin: 50px;
  @media only screen and (max-width: 900px) {
    margin: 0px;
  }
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  .nav-item {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    gap: 35px;
    margin-top: auto;
    .nav-hedder {
      color: #bfd7e8;
      display: flex;
      margin-top: auto;
      flex-direction: column;
      align-content: flex-end;
      padding-bottom: 5px;
      font-size: 32px;
      .hedder {
        font-weight: bold;
        font-family: "Oxygen", sans-serif;
        //if screen is small hide
        @media only screen and (max-width: 900px) {
          display: none;
        }
      }
      .sub-hedder {
        font-family: "Reenie Beanie", cursive;
        // if screen is small hide
        @media only screen and (max-width: 1050px) {
          display: none;
        }
        .and {
          font-family: "Oxygen", sans-serif;
          font-size: 20px;
        }
      }
    }
    .logo {
      height: 100px;
      @media only screen and (max-width: 900px) {
        height: 2rem;
      }
      img {
        height: 100%;
      }
    }
    .nav-link {
      text-decoration: none;
      color: whitesmoke;
      cursor: pointer;
      font-size: 30px;
      transition: all 150ms ease-in-out;
      .text {
        @media only screen and (max-width: 700px) {
          display: none;
        }
      }
      i {
        display: none;
        @media only screen and (max-width: 700px) {
          display: block;
        }
      }
      &:hover {
        color: #ffffff;
        text-shadow: 0 0 10px #ffffff;
      }
      &.active {
        color: #26c3f9;
      }
    }
  }
}
</style>
