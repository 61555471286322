<template>
  <div class="animals">
    <div class="card">
      <div class="text">
        <div class="section">
          <img src="\animals\animal-3.jpg" alt="" />
          <img src="\animals\animal-2.jpg" alt="" />
        </div>
        <div class="section small">
          <img src="\animals\animal-0.jpg" alt="" />
          <div class="title">
            <h1>Animal Logos</h1>
          </div>
          <div class="subtitle">
            Animal Logos was a project centered around creating logos inspired
            by three animals. The goal was to maintain a consistent style and
            elements, so I chose to incorporate spirals and thin lines
            throughout.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioAnimals",
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  border-radius: 25px;
}
.animals {
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    // set size to 100% on small screens

    border-radius: 25px;
    background-color: #252a3d;
    display: flex;
    perspective: 1000px;
    position: relative;
    .text {
      margin: 40px;
      display: flex;
      gap: 20px;
      //set flex to column on small screens
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
      .small {
        width: 50% !important;
        // set width to 100% on small screens
        @media only screen and (max-width: 600px) {
          width: 100% !important;
        }
      }
      .section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .title {
          font-size: 30px;
          font-family: Oxygen;
        }
        .subtitle {
          font-size: 20px;
          font-family: Oxygen;
        }
      }
    }
  }
}
</style>
